// @ts-nocheck
"use client";
import { useState, useEffect } from "react";
import Link from "next/link";

const PreferenceBanner = () => {
  const [preferencesAccepted, setPreferencesAccepted] = useState<boolean | null>(null);

  useEffect(() => {
    const isAccepted = localStorage.getItem("preferencesAccepted");
    setPreferencesAccepted(isAccepted === "true");
  }, []);

  const handleAcceptPreferences = () => {
    localStorage.setItem("preferencesAccepted", "true");
    setPreferencesAccepted(true);
  };

  if (preferencesAccepted === null || preferencesAccepted) {
    return null;
  }

  return (
    <div
      id="preference-banner"
      style={{
        position: "fixed", // Fixed position to ensure it stays on top while scrolling
        bottom: 0, // Position the banner at the bottom of the page
        width: "100%",
        height: "25vh", // 25% of the viewport height
        zIndex: 1000, // High z-index to ensure it stays on top of other elements
        background: "rgba(0, 0, 0, 0.8)", // Transparent background
        color: "#fff",
        padding: "20px", // Padding for content
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column", // Stack content vertically
      }}
    >
      <p style={{ margin: "0 10px" }}>
        We use local storage to save your preferences. By continuing to use our site, you accept this use.
      </p>
      <div>
        <Link href="/docs/legal/terms-and-conditions">
          By using Datadini, you agree to our Terms and Privacy.
        </Link>
      </div>
      <button
        id="accept-preferences"
        onClick={handleAcceptPreferences}
        style={{
          marginTop: "10px", // Space between link and button
          padding: "10px 15px", // Adjust padding for a larger button
          cursor: "pointer",
        }}
      >
        Accept
      </button>
    </div>
  );
};

export default PreferenceBanner;
