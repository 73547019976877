// @ts-nocheck
"use client"
import { useEffect, useRef, useState  } from "react";
import anime from "animejs";
import { Button } from "@/components/ui/button";
import { RegisterLink } from "@kinde-oss/kinde-auth-nextjs/components";
import { DatadiniIcon, DatadiniLogo } from "./components/Icons";
import PreferenceBanner from "@/app/components/PreferenceBanner";
import Link from "next/link";
import styles from "./Home.module.css";

import { isMobile } from 'react-device-detect';

export default function Home() {
  const backgroundRef = useRef(null);

  useEffect(() => {
    const background = backgroundRef.current;
    const colors = ["#FF6347", "#FFD700", "#7CFC00", "#00FA9A", "#00CED1", "#1E90FF", "#FF69B4"];
    const shapes = [
      "M50 15 L61 35 L85 35 L66 50 L75 75 L50 60 L25 75 L34 50 L15 35 L39 35 Z", // Star
      "M50 15 L85 85 L15 85 Z", // Triangle
      "M50 15 A35 35 0 1 0 49.9 15 Z", // Circle
      "M20 20 H80 V80 H20 Z", // Square
      "M50 15 L85 85 L50 70 L15 85 Z", // Diamond
      "M50 15 Q70 35 50 55 Q30 35 50 15 Z" // Heart-like shape
    ];

    
    const createParticle = (x, y) => {
      const particle = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      particle.setAttribute("class", styles.particle);
      particle.setAttribute("viewBox", "0 0 100 100");

      const shapeIndex = Math.floor(Math.random() * shapes.length);
      const colorIndex = Math.floor(Math.random() * colors.length);

      particle.innerHTML = `
        <path d="${shapes[shapeIndex]}" fill="${colors[colorIndex]}" />
      `;
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;

      background.appendChild(particle);

      anime({
        targets: particle,
        translateY: [
          { value: -50, duration: 1000 },
          { value: 0, duration: 1000 }
        ],
        opacity: [
          { value: 1, duration: 500 },
          { value: 0, duration: 1500 }
        ],
        rotate: {
          value: 360,
          duration: 2000,
          easing: "easeInOutSine"
        },
        scale: [
          { value: 1, duration: 500, easing: "easeOutBack" },
          { value: 1.5, duration: 1000, easing: "easeInOutSine" },
          { value: 1, duration: 500, easing: "easeInBack" }
        ],
        complete: () => background.removeChild(particle)
      });
    };

    const onMouseMove = (e) => {
      createParticle(e.clientX, e.clientY);
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  // detect mobile device
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
  }, []);

  if (isMobileDevice === null) {
    // Render nothing or a loader until we know if it's mobile or not
    return null;
  }


  return (
    <>
  

      {/* <PreferenceBanner /> */}
      <section className={`flex items-center justify-center ${styles.background}`} ref={backgroundRef}>
        <div className="relative items-center w-full px-5 py-12 mx-auto lg:px-16 max-w-7xl md:px-12">
          <div className="max-w-4xl mx-auto text-center">
            <div>
              <div className="flex items-center justify-center w-40 h-auto mx-auto">
{/*               <DatadiniLogo className="h-6 w-auto" /> */}
                {/* <DatadiniIcon /> */}
                {/* <DatadiniLogo className="h-10 w-auto" /> */}
              </div>
         {/*      <h1 className="text-5xl font-extrabold tracking-tight lg:text-9xl text-black">
                AI POWERED COMPANY DATA
              </h1> */}

              <h1 className="text-5xl font-extrabold tracking-tight lg:text-9xl text-black">
                Thoughtfully Curated Data.
              </h1>
               <h2 className="text-xl font-bold tracking-tight lg:text-1xl text-black">
                Designed for Simplicity
              </h2>
              <p className="max-w-xl mx-auto mt-4 text-base lg:text-xl text-gray-700">
                Query our database models using natural language.
              </p>
          {/*     <p className="max-w-xl mx-auto mt-4 text-base lg:text-xl text-secondary-foreground text-black">
                Query our database models using natural language.
              </p> */}

              
             
            </div>
            
            <div className="flex justify-center max-w-sm mx-auto mt-4">
              {/* <RegisterLink> */}
              {isMobileDevice ? (
                 <p className="text-base lg:text-base text-green-700">
                 Hello! It looks like you're using a mobile device to access our site. For the best experience, we recommend using a computer. Thank you!
               </p>
              ) : (
                
                <Button size="lg" className="">
                <Link href="/dashboard">Beta Test</Link>
              </Button>
              )}

              
              {/* </RegisterLink> */}
              <PreferenceBanner />
            </div>
            
          </div>
         
        </div>
      </section>
    </>
  );
}
